.page {
  width: 100%;
  padding-top: 64px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        margin-bottom: 16px;
        text-transform: uppercase;

        font-size: 46px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: center;

        span {
          color: #72BF44;
        }
      }
      &__desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: var(--light-blue);
        text-align: center;
      }
    }
    .content {
      width: 100%;
      max-width: 1220px;
      display: flex;
      justify-content: center;
      margin-top: 64px;

      .accordion {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      padding: 0 30px;

      .content {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    &__container {
      .header {
        &__title {
          margin-bottom: 0;
          font-size: 4vw;
          line-height: 6vw;
        }
      }
    }
  }
}

@media (max-width: 655px) {
  .page {
    padding-top: 32px;

    &__container {
      .header {
        &__title {
          font-size: 24px;
          line-height: 31px;
          letter-spacing: -0.02em;
        }
      }
      .content {
        margin-top: 24px;
      }
    }
  }
}