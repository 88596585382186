.page {
  padding-top: 64px;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;

    .left_block {
      padding-top: 10px;

      .header {
        &__title {
          margin-bottom: 16px;
          text-transform: uppercase;
          color: #272D37;

          font-size: 46px;
          font-weight: 700;
          line-height: 60px;
          letter-spacing: -0.02em;
          text-align: center;

          span {
            color: #72BF44;
          }
        }
      }
      .content {
        width: 100%;
        max-width: 1040px;
        display: flex;
        justify-content: center;
        margin-top: 32px;

        .accordion {
          width: 100%;
          max-width: 594px;
        }
      }
    }

    .right_block {
      height: 100%;

      &__content {
        img {
          min-width: 558px;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 0 30px;

      .left_block {
        .content {
          .accordion {
            max-width: 100%;
          }
        }
      }

    }
  }
}
@media (max-width: 1080px) {
  .page {
    &__container {
      .right_block {
        height: 100%;

        &__content {
          img {
            min-width: 400px;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    &__container {
      .left_block {
        .header {
          &__title {
            font-size: 4vw;
            line-height: 6vw;
          }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      align-items: center;
      flex-wrap: wrap;
      gap: 0;

      .left_block {
        padding-top: 10px;

        .header {
          &__title {
            margin-bottom: 16px;
            text-transform: uppercase;
            color: #272D37;

            font-size: 46px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: -0.02em;
            text-align: center;

            span {
              color: #72BF44;
            }
          }
        }
        .content {
          width: 100%;
          max-width: 1040px;
          display: flex;
          justify-content: center;
          margin-top: 32px;

          .accordion {
            width: 100%;
            max-width: 594px;
          }
        }
      }

      .right_block {
        &__content {
          img {
            min-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 655px) {
  .page {
    &__container {
      .left_block {
        .header {
          &__title {
            font-size: 24px;
            line-height: 31px;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}