.form {
  display: flex;
  flex-direction: column;
  gap: 25px;

  &__title {
    color: #272D37;

    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0;
    text-align: left;
  }
  &__desc {
    color: #5F6D7E;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }
  &__names {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  &__input_block {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 5px;
    }
  }

  .errors {
    padding: 0;
    margin: 0;
    color: red;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    white-space: pre-line;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 12px 24px;
    border: 1px solid #D1D5DB;
    box-shadow: 0 1px 2px 0 #00000040;
    border-radius: 6px;
    color: #5F6D7E;

    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  button.button {
    width: 100%;
    height: 46px;
    padding: 0 27px;
    border-radius: 4px;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: none;
  }
}

.checkboxRect {
  margin-right: 10px;
  input[type="checkbox"] {
    display: none;
    + {
      label {
        display: block;
        position: relative;
        padding-left: 30px;
        font-size: 14px;
        padding-top: 2px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: "";
          display: block;
          width: 1.4em;
          height: 1.4em;
          border: 1px solid #d1d2d4;
          border-radius: 0.2em;
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transition: all 0.2s, background 0.2s ease-in-out;
          transition: all 0.2s, background 0.2s ease-in-out;
          background: #ffffff;
        }
      }
    }
    &:hover {
      + {
        label {
          &:hover {
            color: #16A34A;
          }
          &:before {
            background: #b8d2e2;
            box-shadow: inset 0 0 0 2px #fff;
          }
        }
      }
    }
    &:checked {
      + {
        label {
          &:before {
            width: 1.3em;
            height: 1.3em;
            border-radius: 0.2em;
            border: 2px solid #fff;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            background: #16A34A;
            box-shadow: 0 0 0 1px #16A34A;
          }
        }
      }
    }
  }
}
.checkSmallLabel {
  max-width: 464px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.checkSmallLabelText {
  color: #5f5f5f;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.checkSmall {
  transform: scale(1.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  accent-color: #16A34A;
  color: #16A34A;
  border: 1px solid #d1d2d4;
  cursor: pointer;
}

@media (max-width: 992px) {
  .form {
    gap: 15px;

    &__names {
      gap: 15px;
    }
    &__messengers {
      gap: 15px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      padding: 8px;
    }
  }

  .inputPhone {
    background-position: 10px 10px;
  }
}
@media (max-width: 767.9px) {
  .form {
    margin-top: 20px;

    &__title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: start;

      br {
        display: none;
      }
    }
    &__names {
      width: 100%;
      flex-direction: column;
      gap: 15px;

      &_block {
        input {
          margin-bottom: 10px;
        }
      }

      input {
        width: calc(100% - 20px);
      }
    }
  }
}

@media (max-width: 655px) {
  .form {
    &__title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }
}