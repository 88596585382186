.modal {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__text {
    overflow: auto;
    max-height: 80vh;
    max-width: 80vw;
    margin-top: 30px;
    white-space: pre-wrap;
    text-align: center;

    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.1px;
    color: #272D37;
  }
  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 25px;

    button {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      border-radius: 6px;
      padding: 12px 18px;

      box-sizing: border-box;

      font-weight: 600;
      font-size: 15px;
      line-height: 22px;

      &:first-child {
        border: 1px solid #72BF44;
        background: #72BF44;
        box-shadow: var(--box-shadow);

        letter-spacing: 1.5px;
        color: var(--white);

        &:hover {
          border-color: #5cb629;
          background-color: #5cb629;
          color: var(--white);
        }
      }
      &:last-child {
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
        linear-gradient(0deg, #DAE0E6, #DAE0E6);
        border: 1px solid #DAE0E6;
        text-transform: none;

        letter-spacing: 0;
        color: #272D37;

        &:hover {
          color: #272D37;
        }
      }
    }
  }
}

@media (max-width: 777.9px) {
  .modal {
    &__btns {
      flex-wrap: wrap;
    }
  }
}