.item {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #5F6D7E;

    &:hover {
      text-decoration: underline;
    }
  }
}
.modal {
  &__item {
    max-width: 700px;
    height: auto;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}