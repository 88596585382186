.menu {
  ul {
    display: flex;
    flex-direction: row;
    gap: 45px;
  }

  a {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    color: #5F6D7E;
  }
}