.page {
  width: 100%;
  padding-top: 64px;

  background-color: var(--white);

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .left_block {
      &__content {
        img {
          min-width: 558px;
          max-width: 100%;
          height: auto;
        }
      }
    }

    .right_block {
      height: 100%;
      padding-top: 30px;

      .card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--white);
        max-width: 594px;

        &__title {
          max-width: 575px;
          color: #323232;
          text-transform: uppercase;

          font-size: 46px;
          font-weight: 700;
          line-height: 60px;
          letter-spacing: -0.02em;
          text-align: center;

          span {
            color: #72BF44;
          }
        }
        &__desc {
          max-width: 575px;
          color: #5F6D7E;

          font-size: 26px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0;
          text-align: left;

          span {
            display: block;
            margin-top: 24px;
            span {
              display: inline;
              color: #72BF44;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 15px;
      padding: 0 30px;
    }
  }
}
@media (max-width: 992px) {
  .page {
    &__container {
      .left_block {
        &__content {
          img {
            min-width: 300px;
          }
        }
      }

      .right_block {
        .card {
          &__title {
            font-size: 4vw;
            line-height: 6vw;
          }
          &__desc {
            font-size: 3vw;
            line-height: 3vw;
         }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      flex-wrap: wrap;
      gap: 0;

      .left_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card {
          &__title {
            max-width: 575px;
            color: #323232;
            text-transform: uppercase;

            font-size: 46px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: -0.02em;
            text-align: center;

            span {
              color: #72BF44;
            }
          }
          &__desc_mobile {
            color: #5F6D7E;
            margin-top: 12px;
            margin-bottom: 32px;

            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.1px;
            text-align: center;
          }
        }
      }

      .right_block {
        padding-top: 0;

        .card {
          &__title {
            font-size: 46px;
            line-height: 60px;
          }
          &__desc {
            font-size: 26px;
            line-height: 27px;
          }
        }
      }
    }
  }
}
@media (max-width: 655px) {
  .page {
    &__container {
      .left_block {
        .card {
          &__title {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.02em;
          }
          &__desc_mobile {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.1px;
          }
        }
      }

      .right_block {
        .card {
          &__desc {
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0;
          }
        }
      }
    }
  }
}