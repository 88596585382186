.page {
  position: relative;
  width: 100%;
  padding: 75px 0 64px;

  background-color: var(--white);

  .left_logo {
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  .right_logo {
    position: absolute;
    bottom: -20px;
    right: 0;

  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 978.75px;

      .title {
        margin-bottom: 20px;
        text-transform: uppercase;
        color: #323232;

        font-size: 40px;
        font-weight: 700;
        line-height: 68px;
        letter-spacing: 0;
        text-align: center;

        span {
          color: #72BF44;
        }

      }

      .desc {
        max-width: 500px;
        margin-bottom:48px;
        color: #5F6D7E;

        font-size: 26px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: center;
      }

      .partners {
        display: flex;
        flex-direction: column;
        margin-top: 38px;

        &__title {
          margin-bottom: 32px;
          text-transform: uppercase;
          color: #5F6D7E;

          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;

        }
        &__icons {
          display: flex;
          gap: 64px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      .content {
        .title {
          font-size: 2.7vw;
          line-height: 3.7vw;
        }

        .desc {
          font-size: 1.8vw;
          line-height: 2vw;
        }

        .partners {
          &__icons {
            justify-content: center;
            align-items: center;
            gap: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 1250px) {
  .page {
    padding: 40px 0;

    //.left_logo {
    //  max-width: 200px;
    //  height: auto;
    //}
    //.right_logo {
    //  position: absolute;
    //  bottom: 60px;
    //  max-width: 300px;
    //  height: auto;
    //}

    &__container {
      .content {
        .title {
          font-size: 2.7vw;
          line-height: 3.7vw;
        }

        .desc {
          font-size: 1.8vw;
          line-height: 2vw;
        }

        .partners {
          max-width: 400px;
          &__icons {
            width: 100%;
            flex-wrap: wrap;
            gap: 30px;
          }
        }
      }
    }
  }
}
@media (max-width: 1080px) {
  .page {
    .left_logo {
      max-width: 200px;
      height: auto;
    }
    .right_logo {
      position: absolute;
      bottom: 30px;
      max-width: 340px;
      height: auto;
    }

    &__container {
      .content {
        .title {
          margin-bottom: 15px;
          font-size: 2.7vw;
          line-height: 3.7vw;
        }

        .desc {
          margin-bottom: 30px;
          font-size: 1.8vw;
          line-height: 2vw;
        }

        .partners {
          margin-top: 30px;

          &__icons {
            width: 100%;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
@media (max-width: 870px) {
  .page {
    &__container {
      .content {
        .title {
          font-size: 3vw;
          line-height: 3.7vw;
        }

        .desc {
          font-size: 2vw;
          line-height: 2vw;
        }

        .partners {
          margin-top: 30px;

          &__icons {
            width: 100%;
            flex-wrap: wrap;
            gap: 15px;
          }
        }
      }
    }

    .right_logo {
      max-width: 270px;
    }
  }
}

@media (max-width: 767.9px) {
  .page {

    &__container {
      align-items: center;

      .bg_map {
        position: absolute;
        top: -70px;
        left: auto;

        img {
          width: 100%;
          height: auto;
        }
      }

      .top_block {
        margin-bottom: 20px;
      }

      .content {
        .title {
          color: #323232;
        }
        .desc {
          color: #5F6D7E;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    &__container {
      .content {
        padding: 0 15px;

        .title {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0;
        }

        .desc {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
        }
      }
    }
  }
}