.slider-custom {
  .slick-prev {
    left: 0;
  }
  .slick-list {
    text-align: center;
  }
  .slick-slide {
  }
  .slick-next {
    right: 0;
  }
  .slick-dots {
    li {
      width: 15px;
      height: 15px;
      background-color: var(--light-grey2);
      border-radius: 50%;
      bottom: -10px;

      button {
        &:before {
          opacity: 1;
          color: var(--light-grey2);
        }
      }
    }
    li.slick-active {
      background-color: var(--gold-prim);
      button:before {
        opacity: 1;
        color: var(--gold-prim);
      }
    }
  }
}