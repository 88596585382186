.accordion {
  display: flex;
  flex-direction: column;

  &__item {
    margin-bottom: 10px;
    border-radius: 6px;
    background: var(--white2);
    padding: 15px 32px 15px 32px;
    gap: 12px;
    cursor: pointer;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: var(--dark-blue);
      }
      &__btn {
        font-size: 24px;
        padding: 5px;
        border: none;
        background-color: transparent;
        color: var(--light-blue);
        cursor: pointer;
      }
    }
  }
  &__content {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: var(--light-blue);

    &--open {
      display: block;
      padding-top: 20px;
    }
    &--closed {
      display: none;
    }
  }
}

@media (max-width: 655px) {
  .accordion {
    &__item {
      .header {
        &__title {
          color: #313131;

          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.1px;

        }
      }
    }
    &__content {
      color: #5F6D7E;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }
}