.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  padding: 25px 20px;
  border-radius: 5px;
  gap: 16px;
  height: 240px;

  //background: linear-gradient(0deg, var(--white), var(--white)),
  //linear-gradient(0deg, var(--neutral), var(--neutral));
  //box-shadow: var(--box-shadow);
  //border: 1px solid var(--neutral);

  &__img {
    width: 40px;
    height: 28px;
    margin-bottom: 16px;
  }

  &__title {
    max-width: 268px;
    color: #1B192A;

    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
  }

  &__desc {
    max-width: 268px;
    color: #5F6D7E;

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
  }
}

.cards {
  width: 100%;
  display: flex;
  gap: 15px;

  .card {
    @extend .card;
  }
}

.cards_slider {
  max-width: 400px;

  .card {
    @extend .card;

    max-width: 343px;
    min-height: 376px;
    text-align: start;

    &__img {
      margin-bottom: 26px;
    }
    &__title {
      margin-bottom: 10px;
    }
    &__desc {
      p {
        &:last-child {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .card {
    height: 180px;

    &__title {
      font-size: 1.6vw;
      line-height: 1.6vw;
    }
    &__desc {
      font-size: 1.4vw;
      line-height: 1.4vw;
    }
  }
}
@media (max-width: 767.9px) {
  .cards_slider {
    max-width: 318px;

    .card {
      max-width: 318px;
      min-height: 278px;
      overflow: auto;
      gap: 0;
      padding: 0 15px;

      &__title {
        max-width: 278px;
        margin-bottom: 0;

        font-size: 26px;
        line-height: 30px;
      }

      &__desc {
        max-width: 278px;

        font-size: 16px;
        line-height: 22px;

        p {
          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .cards_slider {
    .card {
      &__title {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0;
      }
      &__desc {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0;
      }
    }
  }
}
@media (max-width: 400px) {
  .cards_slider {
    max-width: 300px;

    .card {
      max-width: 318px;
      min-height: 278px;
      padding: 0 15px;

      &__title {
        max-width: 278px;
      }

      &__desc {
        max-width: 278px;
      }
    }
  }
}