.page {
  width: 100%;
  margin-top: -1px;

  background-color: #ECEEF1;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;

    .left_block {
      max-width: 496px;
    }
    .right_block {
      max-width: 720px;
      max-height: 641px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 32px;

      .left_block {
        flex: 0.4;
        max-width: 100%;
        padding: 30px;
      }
      .right_block {
        flex: 0.6;

        img {
          min-width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      gap: 0;

      .left_block {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        form {
          max-width: 500px;
          padding: 30px;

          h3 {
            font-size: 36px;
            font-weight: 700;
            line-height: 47px;
            letter-spacing: 0;
            text-align: left;
          }

          button {
            max-width: 300px;
            align-self: center;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    &__container {
      .left_block {
        form {
          h3 {
            font-size: 36px;
            line-height: 47px;
            letter-spacing: 0;
          }
          p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0;
          }

          button {
            max-width: 300px;
            align-self: center;
            margin-top: 15px;
          }
        }
      }
    }
  }
}